import {
    FETCH_BIRTHDAYS,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENT_FAILURE,
    GET_UNSUBSCRIBE_EVENT,
    ADD_EVENT_REQUEST,
    EDITE_EVENT_REQUEST,
    DELETE_EVENT_REQUEST,
    SELECT_EVENT_REQUEST
} from '../constants/action-types'

const initState = {    
    birthdays : [],
    events:[],
    event: null,
    unsubscribeCollection: null,
    error: null
}

export default (state = initState, action) => {
    switch(action.type) {
        case FETCH_BIRTHDAYS:
            console.log('FETCH_BIRTHDAYS')
            return {
                ...state,
                birthdays: action.payload,
            }
        
        case FETCH_EVENT_SUCCESS:
            console.log('FETCH_EVENT_SUCCESS')
            return {
                ...state,
                events: action.payload,
            }
        
        case GET_UNSUBSCRIBE_EVENT:
            console.log('GET_UNSUBSCRIBE_EVENT')
            return {
                ...state, 
                unsubscribeCollection: action.payload
            }

        case FETCH_EVENT_FAILURE:
            console.log('FETCH_EVENT_FAILURE')
            return {
                events: [],
                event: null,
                error: action.payload,
                unsubscribeCollection: null
            }

        case ADD_EVENT_REQUEST:
            console.log('ADD_EVENT_REQUEST')
            return {
                ...state,
                event: action.payload,
            }

        case EDITE_EVENT_REQUEST:
            console.log('EDITE_EVENT_REQUEST')
            return {
                ...state,
                event: action.payload,
            }

        case DELETE_EVENT_REQUEST:
            console.log('DELETE_EVENT_REQUEST')
            return {
                ...state,
                event: null
            }

        case SELECT_EVENT_REQUEST:
            console.log('SELECT_EVENT_REQUEST')
            return {
                ...state,
                event: action.payload
            }
    
        default:
            return state;
    }
}