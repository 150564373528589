import React from 'react'
import { useDispatch } from "react-redux"

import {
  editEvent
} from '../../redux/actions/event'

import {
  selectEvent,
  displayModalFormEvent
} from '../../redux/actions/index'

import { getTimesTamp } from '../../Firebase/firebase'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import bootstrapPlugin from '@fullcalendar/bootstrap'
import rrulePlugin from "@fullcalendar/rrule"
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import frLocale from "@fullcalendar/core/locales/fr"

import './Calendar.scss'

import Swal from 'sweetalert2';
import { getBirthdayDate } from '../../Features/functions'

export default function Calendar(props) {
  const dispatch = useDispatch();

  const generateEvent = arg => {    
    let event = {
      id: arg.event.id,
      allDay: arg.event.allDay,
      hex: arg.event.backgroundColor,
      title: arg.event.title,
      start: getTimesTamp(arg.event.start.getTime()),
      freq: arg.event.groupId
    }

    if (arg.event.end === null) {
      let endDate;
      if (arg.event.allDay) {
        endDate = new Date(arg.event.start.getFullYear(),arg.event.start.getMonth(),arg.event.start.getDate()+1);
        event.end = getTimesTamp(endDate.getTime())
      } else {
        endDate = arg.event.start.setHours(arg.event.start.getHours() + 1)
        event.end = getTimesTamp(endDate)
      }      
    } else {
      event.end = getTimesTamp(arg.event.end.getTime())
    }
    
    return event
  }

  const handleShow = arg => {
    if (!arg.event.startEditable) {
      return
    }
    dispatch(selectEvent(generateEvent(arg)))
    dispatch(displayModalFormEvent(true))
  }

  const handleSelectClick = arg => {
    dispatch(selectEvent({start: getTimesTamp(arg.start.getTime()), end: getTimesTamp(arg.end.getTime()), allDay: arg.allDay, freq: ''}))
    dispatch(displayModalFormEvent(true))
  }

  const handleChangeDateEvent = arg => {
    Swal.fire({
      title: "Vous allez modifier la date/horaire de l'évènement ",
      text: 'Etes-vous sûr de vouloir continuer ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'non',
      confirmButtonText: 'oui',
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        dispatch(editEvent(generateEvent(arg)))
      } else {
        arg.revert()
      }
    })
  }

  return (
    <div id='calendar'>
      <FullCalendar
        ref={ref => {
          if (ref) {
            let date;
            if (props.contact) {
              date = getBirthdayDate(props.contact.birthday.toDate()).split('/')
              date = new Date().getFullYear().toString() + '-' + date[1] + '-' + date[0]
              ref.calendar.gotoDate(date)
            }           
          }
        }}
        selectable={true}
        events={[...props.eventStore.events, ...props.eventStore.birthdays]}
        select={handleSelectClick}
        eventClick={handleShow}
        eventDrop={handleChangeDateEvent}
        eventResize={handleChangeDateEvent}
        plugins={[ dayGridPlugin, interactionPlugin, rrulePlugin,timeGridPlugin, bootstrapPlugin, listPlugin  ]}
        themeSystem= 'bootstrap'
        navLinks={true}
        height={650}
        contentHeight={650}
        header={{
          left: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          center: 'title',
          right: 'prevYear,prev,next,nextYear'
        }}
        locale={frLocale}
        editable={true}
        eventLimit={true}
        showNonCurrentDates={false}
        nowIndicator={true}
        slotDuration={'00:15:00'}
      />
    </div>
  )  
}