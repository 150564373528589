/*---------  AUTH ---------*/
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

/*---------  APP ---------*/
export const IS_LOADING = "IS_LOADING";
export const DISPLAY_MODAL_FORM_CONTACT = "DISPLAY_MODAL_FORM_CONTACT";
export const DISPLAY_MODAL_FORM_EVENT = "DISPLAY_MODAL_FORM_EVENT";
export const DISPLAY_MODAL_CALENDAR = "DISPLAY_MODAL_CALENDAR";
export const DISPLAY_MODAL_WEATHER = "DISPLAY_MODAL_WEATHER";

/*---------  CONTACT ---------*/
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILURE = "FETCH_CONTACT_FAILURE";
export const GET_UNSUBSCRIBE_CONTACT = "GET_UNSUBSCRIBE_CONTACT";

export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const EDITE_CONTACT_REQUEST = "EDITE_CONTACT_REQUEST";
export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";

export const SELECT_CONTACT = "SELECT_CONTACT";
export const SEARCH_CONTACT = "SEARCH_CONTACT";

/*---------  EVENT ---------*/
export const FETCH_BIRTHDAYS = "FETCH_BIRTHDAYS";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_FAILURE = "FETCH_EVENT_FAILURE";
export const GET_UNSUBSCRIBE_EVENT = "GET_UNSUBSCRIBE_EVENT";

export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const EDITE_EVENT_REQUEST = "EDITE_EVENT_REQUEST";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";

export const SELECT_EVENT_REQUEST = "SELECT_EVENT_REQUEST";