import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    VERIFY_REQUEST,
    SEARCH_CONTACT, 
    FETCH_CONTACT_SUCCESS, 
    FETCH_CONTACT_FAILURE,
    VERIFY_SUCCESS,
    GET_UNSUBSCRIBE_CONTACT,
    DISPLAY_MODAL_FORM_CONTACT,
    DISPLAY_MODAL_FORM_EVENT,
    DISPLAY_MODAL_CALENDAR,
    DISPLAY_MODAL_WEATHER,
    SELECT_CONTACT,
    ADD_CONTACT_REQUEST,
    EDITE_CONTACT_REQUEST,
    DELETE_CONTACT_REQUEST,
    IS_LOADING,
    FETCH_BIRTHDAYS,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENT_FAILURE,
    SELECT_EVENT_REQUEST,
    GET_UNSUBSCRIBE_EVENT,
    ADD_EVENT_REQUEST,
    EDITE_EVENT_REQUEST,
    DELETE_EVENT_REQUEST
} from '../constants/action-types';

/* ------ AUTH ------ */

export const requestLogin = () => {
    return {
      type: LOGIN_REQUEST
    };
  };

export const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};


export const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    error
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

export const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

export const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

/* ------ APP ------ */

export const isLoading = value => {
  return {
    type: IS_LOADING,
    payload: value
  }
}

export const displayModalFormContact = value => {
  return {
      type: DISPLAY_MODAL_FORM_CONTACT,
      payload: value
  }
}

export const displayModalFormEvent = value => {
  return {
      type: DISPLAY_MODAL_FORM_EVENT,
      payload: value
  }
}

export const displayModalCalendar = value => {
  return {
      type: DISPLAY_MODAL_CALENDAR,
      payload: value
  }
}

export const displayModalWeather = value => {
  return {
      type: DISPLAY_MODAL_WEATHER,
      payload: value
  }
}

/* ------ CONTACT ------ */

export const fetchContactsSuccess = contacts => {
    return { 
        type: FETCH_CONTACT_SUCCESS,
        payload: contacts
    }
};

export const fetchContactsFailure = error => {
    return { 
        type: FETCH_CONTACT_FAILURE,
        payload: error
    }
};

export const getUnsubscribeContact = fct => {
    return {
        type : GET_UNSUBSCRIBE_CONTACT,
        payload: fct
    }
}

export const searchContactsResult = (result) => {
  return {
    type: SEARCH_CONTACT,
    payload: result
  }
}

export const selectContact = contact => {
    return {
        type: SELECT_CONTACT,
        payload: contact
    }
}

export const addContactRequest = contact => {
    return {
        type: ADD_CONTACT_REQUEST,
        payload: contact
    }
}

export const editeContactRequest = contact => {
    return {
        type: EDITE_CONTACT_REQUEST,
        payload: contact
    }
}

export const deleteContactRequest = () => {
  return {
      type: DELETE_CONTACT_REQUEST
  }
}

/* ------ EVENT ------ */

export const fetchBirthday = birthdays => {
  return { 
      type: FETCH_BIRTHDAYS,
      payload: birthdays
  }
};
export const fetchEventsSuccess = events => {
  return { 
      type: FETCH_EVENT_SUCCESS,
      payload: events
  }
};

export const fetchEventsFailure = error => {
  return { 
      type: FETCH_EVENT_FAILURE,
      payload: error
  }
};

export const getUnsubscribeEvent = fct => {
  return {
      type : GET_UNSUBSCRIBE_EVENT,
      payload: fct
  }
}

export const selectEvent = event => {
  return {
      type : SELECT_EVENT_REQUEST,
      payload: event
  }
}

export const addEventRequest = event => {
  return {
      type: ADD_EVENT_REQUEST,
      payload: event
  }
}

export const editeEventRequest = event => {
  return {
      type: EDITE_EVENT_REQUEST,
      payload: event
  }
}

export const deleteEventRequest = () => {
  return {
      type: DELETE_EVENT_REQUEST
  }
}