import appReducer from './app'
import authReducer from './auth'
import contactReducer from './contact'
import eventReducer from './event'

import { combineReducers } from 'redux'

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    contact: contactReducer,
    event: eventReducer,    
});
