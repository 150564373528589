import React from 'react';
import { useDispatch } from "react-redux";
import { displayModalWeather } from '../../redux/actions'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Weather from '../Weather'


export default function ModalWeather(props) {
    const dispatch = useDispatch();
    const openModal = props.displayModal;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseModal = () => {
        dispatch(displayModalWeather(false))
      };
    return (
            <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="responsive-dialog-title"
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title">{"Météo"}</DialogTitle>
            <DialogContent>
                <Weather contact={props.contact}/>
            </DialogContent>
            <DialogActions>
                <Button 
                    autoFocus
                    onClick={handleCloseModal}
                    color="secondary">
                    Retour
                </Button>
            </DialogActions>
        </Dialog>   
    )  
}