import React from 'react';
import { useSelector } from "react-redux";
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Main from '../components/Main';
import Login from '../components/Login';

export default function App() {
  const auth = useSelector(state => state.auth);
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        component={Main}
        isAuthenticated={auth.isAuthenticated}
        isVerifying={auth.isVerifying}
      />
      <Route path="/login" component={Login} />
    </Switch>
  );
}
