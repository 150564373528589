import {
    SEARCH_CONTACT,
    FETCH_CONTACT_SUCCESS, 
    FETCH_CONTACT_FAILURE,
    GET_UNSUBSCRIBE_CONTACT,
    SELECT_CONTACT,
    ADD_CONTACT_REQUEST,
    EDITE_CONTACT_REQUEST,
    DELETE_CONTACT_REQUEST
} from '../constants/action-types'

const initState = {
    contacts:[],
    contactsFound:[],
    contact: null,
    unsubscribeCollection: null,
    error: null
}

export default (state = initState, action) => {
    switch(action.type) {
        case FETCH_CONTACT_SUCCESS:
            console.log('FETCH_CONTACT_SUCCESS')
            return {
                ...state,
                contacts: action.payload
            }

        case GET_UNSUBSCRIBE_CONTACT:
            console.log('GET_UNSUBSCRIBE_CONTACT')
            return {
                ...state, 
                unsubscribeCollection: action.payload
            }
        
        case FETCH_CONTACT_FAILURE:
            console.log('FETCH_CONTACT_FAILURE')
            return {
                contacts: [],
                contactsFound:[],
                contact: null,
                error: action.payload,
                unsubscribeCollection: null
            }

        case ADD_CONTACT_REQUEST:
            console.log('ADD_CONTACT_REQUEST')
            return {
                ...state,
                contact: action.payload,
            }

        case EDITE_CONTACT_REQUEST:
            console.log('EDITE_CONTACT_REQUEST')
            return {
                ...state,
                contact: action.payload,
            }

        case DELETE_CONTACT_REQUEST:
            console.log('DELETE_CONTACT_REQUEST')
            return {
                ...state,
                contact: null
            }
        
        case SELECT_CONTACT:
            console.log('SELECT_CONTACT')
            return {
                ...state,
                contact: action.payload,
                contactsFound:[],
            }

        case SEARCH_CONTACT:
            console.log('SEARCH_CONTACT')
            return {
                ...state,
                contactsFound: action.payload
            }
    
        default:
            return state;
    }
}