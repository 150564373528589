import React from 'react';
import { useDispatch } from "react-redux";
import {
  displayModalCalendar
} from '../../redux/actions/index';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Calendar from '../Calendar/Calendar';

export default function ModalContactForm(props) {
  const dispatch = useDispatch();
  const openModal = props.displayModal; 
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseCalendar = () => {
    dispatch(displayModalCalendar(false))
  };  
  
  return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={openModal}
        onClose={handleCloseCalendar}
        aria-labelledby="responsive-dialog-title"
        maxWidth={'md'}
        style={{zIndex:1000}}
      >
        <DialogTitle id="responsive-dialog-title">{"Calendrier"}</DialogTitle>
        <DialogContent>
          <Calendar contacts={props.contacts} contact={props.contact} eventStore={props.eventStore}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseCalendar} color="secondary">
            Retour
          </Button>
        </DialogActions>
      </Dialog>
  );
}