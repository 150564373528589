import {
    IS_LOADING,
    DISPLAY_MODAL_FORM_CONTACT,
    DISPLAY_MODAL_FORM_EVENT,
    DISPLAY_MODAL_CALENDAR,
    DISPLAY_MODAL_WEATHER,
} from '../constants/action-types'

const initState = {
    isloading: false,
    displayModalFormContact: false,
    displayModalFormEvent: false,
    displayModalCalendar: false,
    displayModalWeather: false,
    alert:{
        display: false,
        type: null,
        message: null
    },
}

export default (state = initState, action) => {
    switch(action.type) {
        case IS_LOADING:
            console.log('IS_LOADING')
            return {
                ...state,
                loading: action.payload,
            }

        case DISPLAY_MODAL_FORM_CONTACT:
            console.log('DISPLAY_MODAL_FORM_CONTACT')
            return {
                ...state,
                displayModalFormContact: action.payload
            }
        
        case DISPLAY_MODAL_FORM_EVENT:
            console.log('DISPLAY_MODAL_FORM_EVENT')
            return {
                ...state,
                displayModalFormEvent: action.payload
            }

        case DISPLAY_MODAL_CALENDAR:
            console.log('DISPLAY_MODAL_CALENDAR')
            return {
                ...state,
                displayModalCalendar: action.payload
            }
    
        case DISPLAY_MODAL_WEATHER:
            console.log('DISPLAY_MODAL_WEATHER')
            return {
                ...state,
                displayModalWeather: action.payload
            }
        default:
            return state;
    }
}
