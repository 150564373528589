import React from 'react';
import { useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import PowerSettingsNewTwoToneIcon from '@material-ui/icons/PowerSettingsNewTwoTone';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import {
    displayModalFormContact,
    selectContact,
    displayModalCalendar,
    displayModalWeather
  } from '../redux/actions/index';

import { logoutUser } from '../redux/actions/auth';

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));
  
export default function MenuFloat(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
      setOpen(true);
  };    
  
  const handleClose = () => {
      setOpen(false);
  };

  const actions = [
      { 
          icon: <PowerSettingsNewTwoToneIcon />, 
          name: 'Déconnexion',
          showTooltip: false,
          click: () => dispatch(logoutUser(props.unsubscribeContact, props.unsubscribeEvent))
      },
      { 
          icon: <CalendarTodayIcon />,
          name: 'Calendrier',
          showTooltip: false,
          click: () => {
              dispatch(displayModalCalendar(true));
          }, 
      },
      { 
          icon: <FilterDramaIcon />,
          name: 'Météo',
          showTooltip: false,
          click: () => {
              dispatch(displayModalWeather(true));
          },
      },
      { 
          icon: <PersonAddOutlinedIcon />, 
          name: 'nouveau contact',
          showTooltip: false,
          click: () => {
              dispatch(selectContact(null))
              dispatch(displayModalFormContact(true))
          },
      },
      
    ];

  return (
      <SpeedDial
      ariaLabel="SpeedDial"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}                      
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      hidden={props.hidden}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen={action.showTooltip}
          onClick={action.click}
        />
      ))}
    </SpeedDial>
  );
}