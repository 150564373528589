import React from 'react';
import {useDispatch} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';

import {
  displayModalCalendar,
  displayModalFormContact,
  searchContactsResult,
  selectContact,
} from '../redux/actions/index';

import { getBirthdayDate} from '../Features/functions'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {green, orange} from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';

import AlternateEmailTwoToneIcon from '@material-ui/icons/AlternateEmailTwoTone';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PhoneIcon from '@material-ui/icons/Phone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import {
  searchContacts, sortContactsByAge, sortContactsByDate,
  sortContactsByFirstName,
  sortContactsByLastName
} from "../redux/actions/contact";
import Button from '@material-ui/core/Button';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CakeIcon from '@material-ui/icons/Cake';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: '100vh',
    minHeight: '100vh',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
  },
  contact: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  inline: {
    display: 'inline',
  },
  chip: {
    margin: theme.spacing(1),
  },
  chipGroup: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
}));

export default function ContactList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let contacts = props.contactsFound.length > 0 ? props.contactsFound : props.contacts;
  const contactSelect = props.contact;

  if (contactSelect) {
    contacts = contacts.filter(contact => {
        return contact.id !== contactSelect.id
      })
  }

  const keyEnterPress = (e) => {
    dispatch(searchContacts(e.target.value, props.contacts))
  };

  const nextBirthday = date => {
    let today = new Date();
    let birthDate = date.toDate();

    birthDate.setFullYear(today.getFullYear());
    
    let time_diff = birthDate.getTime() - today.getTime();

    let days_diff = time_diff / (1000 * 3600 * 24);

    if (-31 > days_diff || days_diff > 31) {return null}
    
    let label = '';
    let color = '';
    let style = null;

    if (days_diff > 0 ) {
      label = `J - ${Math.ceil(Math.abs(days_diff))}`;
      color = 'primary' ;
      style = {backgroundColor:green[500]};
    } else if(days_diff < -1) {
      label = `J + ${Math.ceil(Math.abs(days_diff + 1))}`;
      color = 'secondary';
    } else {
      label = `Aujourd'hui`;
      color = 'primary';
      style = {backgroundColor:'orange'};
    }

    return <Chip
      color= {color}
      label={label}
      style={style}
      className={classes.chip}
    />

  }

  const sendEmail = () => {
    Swal.fire({
      title: 'Envoyer un email à \n' + contactSelect.firstName + '?',
      text: 'Vous serez rediriger vers votre boite mail',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText:
        'Continuer <i class="fa fa-paper-plane"></i>',
      cancelButtonText:
        'Annuler',
        cancelButtonColor: '#d33',
      reverseButtons: true,
    })
    .then((result) => {
      if (result.value) {
        window.open('mailto:'+contactSelect.email);
      }
    })
  }
  
  return (
    <div className={classes.root}>
      <List dense className={classes.list}>
        <ListItem>
          <ListItemText
              primary={
                <TextField
                    variant="outlined"
                    margin="normal"
                    id="search"
                    label="Recherche"
                    type="search"
                    name="search"
                    onChange={keyEnterPress}
                    fullWidth
                />
              }
          />
        </ListItem>
        <ListItem>
          <ListItemText
              primary={
                <>
                  <div className="w-100 d-flex justify-content-around">
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<PersonPinIcon />}
                        onClick={() => {dispatch(sortContactsByLastName(props.contacts))}}
                    >
                      Nom
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<PersonPinCircleIcon />}
                        onClick={() => {dispatch(sortContactsByFirstName(props.contacts))}}
                    >
                      Prénom
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<DateRangeIcon />}
                        onClick={() => {dispatch(sortContactsByAge(props.contacts))}}
                    >
                      Age
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={() => {dispatch(sortContactsByDate(props.contacts))}}
                    >
                      <CakeIcon/>
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={() => {dispatch(searchContactsResult(props.contacts.reverse()))}}
                    >
                      <SwapVertIcon/>
                    </Button>
                  </div>
                </>
              }
          />
        </ListItem>
        <Divider/>
        { (contactSelect) ?  <List dense className={classes.contact}>
          <ListItem
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <Avatar
                alt={contactSelect.firstName + ' ' + contactSelect.lastName}
                src={`https://eu.ui-avatars.com/api/?bold=true&name=${contactSelect.firstName}+${contactSelect.lastName}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  style={{ color: '#8c5e5e' }}
                >
                  {contactSelect.firstName + ' ' + contactSelect.lastName}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  { contactSelect.age } ans
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => {
                  dispatch(displayModalFormContact(true))
                  }}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => {
                  dispatch(selectContact(null))
                  }}
                >
                  <CancelTwoToneIcon fontSize="large" />
                </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <div className={classes.chipGroup}>
            {!isNaN(contactSelect.phone) ?
            <Chip
              className={classes.chip}
              color='primary'
              icon={<PhoneIcon />}
              label={`0${parseInt(contactSelect.phone)}`}
            /> : null }
            {contactSelect.email !== '' ?
            <Chip
              className={classes.chip}
              color='primary'
              style={{backgroundColor:'#2196f3'}}
              icon={<AlternateEmailTwoToneIcon />}
              label={contactSelect.email}
              onClick={sendEmail}
            /> : null }
            <Chip
              clickable
              className={classes.chip}
              color='primary'
              style={{backgroundColor:orange[500]}}
              icon={<DateRangeIcon />}
              label={getBirthdayDate(contactSelect.birthday.toDate())}
              onClick={() => dispatch(displayModalCalendar(true))}
            />
            {nextBirthday(contactSelect.birthday)}
          </div>
        </List> :
          contacts.map((contact, key) => {
            return (
              <div key={key}>
                <ListItem
                  button
                  alignItems="flex-start"
                  onClick={() => dispatch(selectContact(contact))}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={contact.firstName + ' ' + contact.lastName}
                      src={`https://eu.ui-avatars.com/api/?bold=true&background=0D8ABC&color=fff&name=${contact.firstName}+${contact.lastName}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        style={{ color: '#0D8ABC' }}
                      >
                        {contact.firstName + ' ' + contact.lastName}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        { contact.age } ans
                      </React.Fragment>
                    }
                  />
                  {nextBirthday(contact.birthday)}
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            );
          }) }
        </List>
    </div>
  );
}