import React, {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { fetchContacts } from '../redux/actions/contact'
import { fetchEvents } from '../redux/actions/event'

import CssBaseline from '@material-ui/core/CssBaseline'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import Leaflet from './Map/Leaflet'
// import MenuTop from './MenuTop'
import MenuFloat from './MenuFloat'
import ContactList from './List'

import ModalContactForm from './Modals/ModalContactForm'
import ModalEventForm from './Modals/ModalEventForm'
import ModalCalendar from './Modals/ModalCalendar'
import ModalWeather from './Modals/ModalWeather'

import './Main.scss'  

export default function Main() {
    const appStore = useSelector(state => state.app);
    const contactStore = useSelector(state => state.contact);
    const eventStore = useSelector(state => state.event);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchContacts())
      dispatch(fetchEvents())
    }, [dispatch]);
    
    if (appStore.loading) {
      return( 
        <Backdrop open={true}>
             <CircularProgress color="inherit" />
         </Backdrop>
        )        
    } else if (contactStore.error || eventStore.error) {
      return (
        <div>
          Erreur : 
          <ul>
            {contactStore.error ? <li>{contactStore.error.message}</li> : null}
            {eventStore.error ? <li>{eventStore.error.message}</li> : null}
          </ul>
        </div>
      )
    } else {
        return (
          <div>
            <React.Fragment>
            <CssBaseline />
              <MenuFloat
                hidden={
                  appStore.displayModalFormContact ||
                  appStore.displayModalCalendar ||
                  appStore.displayModalWeather
                  ? true: false
                }
                contact={contactStore.contact}
                contacts={contactStore.contacts}
                events={eventStore.events}
                unsubscribeContact={contactStore.unsubscribeCollection}
                unsubscribeEvent={eventStore.unsubscribeCollection}
              />
                {/* <MenuTop 
                  contact={contactStore.contact} 
                  contacts={contactStore.contacts}
                  events={eventStore.events}
                  unsubscribeContact={contactStore.unsubscribeCollection}
                  unsubscribeEvent={eventStore.unsubscribeCollection}
                /> */}
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <ContactList 
                      contacts={contactStore.contacts} 
                      contact={contactStore.contact} 
                      contactsFound={contactStore.contactsFound}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Leaflet 
                      openInfos={false} 
                      contact={contactStore.contact} 
                      contacts={contactStore.contacts
                    }/>                    
                </Grid>
            </Grid>
            </React.Fragment>
            <ModalContactForm 
              displayModal={appStore.displayModalFormContact} 
              contact={contactStore.contact}
            />
            <ModalEventForm
              displayModal={appStore.displayModalFormEvent}
              event={eventStore.event}
            />
            <ModalCalendar 
              displayModal={appStore.displayModalCalendar}
              contact={contactStore.contact}
              eventStore={eventStore}
            />
            <ModalWeather 
              displayModal={appStore.displayModalWeather}
              contact={contactStore.contact}
            />
          </div>            
        );
    }
}