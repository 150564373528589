import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyA3jIWT_GO6xYwzseva2JvPN0AU1si7Fxg",
    authDomain: "my-app-f013e.firebaseapp.com",
    databaseURL: "https://my-app-f013e.firebaseio.com",
    projectId: "my-app-f013e",
    storageBucket: "my-app-f013e.appspot.com",
    messagingSenderId: "369528287841",
    appId: "1:369528287841:web:d450030ae27e43941ff020",
    measurementId: "G-RSKKGX69VK"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);

myFirebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
const baseDb = myFirebase.firestore();

export const db = baseDb;

export const contactCollection = db.collection('contacts');
export const eventCollection = db.collection('events');

export const getTimesTamp = dateTime => {
    return new firebase.firestore.Timestamp(dateTime/1000,0)
}

export const getGeoPoint = (latitude, longitude) => {
    return new firebase.firestore.GeoPoint(latitude, longitude)
}