import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Localisation(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState(false)
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading && !query) {
      return undefined;
    }

    (async () => {
      const response = await fetch('https://api-adresse.data.gouv.fr/search/?q='+ query + '&type=housenumber&autocomplete=1');
      const localisations = await response.json();
      let results = [];
      if (active && localisations.features.length > 0) {
        localisations.features.map(localisation => {
          results = [...results, {label:localisation.properties.label, geopoint:localisation.geometry.coordinates}]
          return setOptions(results)
        })       
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, query]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
      <Autocomplete
        className="localisation"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, value) => {
          setQuery(value)
          setOpen(true);
        }}
        getOptionSelected={(option, value) => option.label === value.label}
        getOptionLabel={(option) => {
          return option.label}}
        options={options}
        loading={loading}
        onChange={(event, value) => {
          props.addValue(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            required={typeof props.defaultValue === 'string' ? false : true}
            label={props.label}
            placeholder={typeof props.defaultValue === 'string' ? props.defaultValue : null}
            helperText={typeof props.defaultValue === 'string' ? props.defaultValue : null}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
  );
}
