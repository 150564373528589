import React, {useState} from 'react'
import Iframe from 'react-iframe'
import Localisation from './Localisation'


export default function Weather(props) {
  const [lat, setLat] = useState(props.contact ? props.contact.geopoint.latitude : null)
  const [lon, setLon] = useState(props.contact ? props.contact.geopoint.longitude : null)

  const onChange = data => {
    setLat(data.geopoint[1])
    setLon(data.geopoint[0])
  };
  
  const url = "https://embed.windy.com/embed2.html?lat="+ lat +"&lon="+ lon +"&zoom=8&level=surface&overlay=wind&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&detailLat="+ lat +"&detailLon="+ lon +"&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1"
  
  return (
      <div className='weather'>
          <div className='formWeather'>
            <Localisation
            label='Rechercher adresse'
            addValue={onChange}
            defaultValue={props.contact ? props.contact.address : null}
            />
          </div>
          {(lat && lon) ?
          <div>
            <hr/>
            <Iframe 
            width="100%"
            height='650'
            src={url}
            frameborder="0"
            /> 
          </div> : null
          }
      </div>            
  )  
}