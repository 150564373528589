import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";

import {
  selectContact
} from '../../redux/actions/index';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import './Leaflet.css'

import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) =>
  createStyles({
    listLeaflet: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    }
  }),
);

export default function Leaflet(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const contacts = props.contacts;
  const contact = props.contact;
  const [center, setCenter] = useState([46.3668985,2.0188071])
  const [mobile, setMobile] = useState(true)
  
  const groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

  useEffect(() => {
    if (contact) {
      setCenter([contact.geopoint.latitude + 0.5,contact.geopoint.longitude])
    } else {
      setCenter([46.3668985,2.0188071])
    }

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
    {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [contact])  

  return(
    <Map 
      center={center}
      zoom={contact ? 8 : 6.4} style={{height: "100vh", width: "100%"}} 
      id='map'
      scrollWheelZoom={mobile}  
      dragging={mobile}
      tap={mobile}
       
    >
        <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {contacts ? Object.entries(groupBy(contacts, 'address')).map(location => 
            <Marker
              ref={ref => {
                if (ref && contact && (contact.address === location[0])) {
                  ref.leafletElement.openPopup()
                } else if (ref) {
                  ref.leafletElement.closePopup()
                }
              }}
              position={[location[1][0].geopoint.latitude, location[1][0].geopoint.longitude]} 
              key={location[1][0].id}
            >
                <Popup closeButton={true} closeOnClick={false}>
                  <div>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.listLeaflet}
                  >
                    {location[1].map((contact, index) => 
                    <div key={index}>
                    <ListItem 
                      button
                      onClick={() => {
                        dispatch(selectContact(contact))
                        }}
                    >
                    <ListItemAvatar>
                      <Avatar
                        alt={contact.firstName + ' ' + contact.lastName}
                        src={`https://eu.ui-avatars.com/api/?bold=true&background=0D8ABC&color=fff&name=${contact.firstName}+${contact.lastName}`}
                      />
                    </ListItemAvatar>
                      <ListItemText 
                        primary={
                          <Typography 
                            variant="h6" 
                            style={{ color: '#0D8ABC' }}
                          >
                            {contact.firstName + ' ' + contact.lastName}
                          </Typography>
                        } 
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </div>
                    )}
                  </List>
                  <List component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.listLeaflet}
                  >
                    <ListItem>
                      <ListItemIcon>
                      <Icon style={{ fontSize: 40 }}>home</Icon>
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Typography 
                          variant="h6"
                        >
                          {location[1][0].address}
                        </Typography>                          
                        } 
                      />
                    </ListItem>
                    </List>
                  </div>
                </Popup>
            </Marker>
        ) : 
        <Marker position={[46.3668985,2.0188071]}></Marker>
        }
    </Map>
  )
}