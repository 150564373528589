export const getAge = date => {
    let today = new Date();
    let birthDate = date.toDate();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  export const getBirthdayDate = date => {
      let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())
      let month = date.getMonth() > 8 ? date.getMonth() + 1 : ('0' + (date.getMonth() + 1))
      let year = date.getFullYear()
    return (day + '/' + month + '/' + year)
  }