import {
    requestLogin,
    receiveLogin,
    loginError,
    requestLogout,
    receiveLogout,
    logoutError,
    verifyRequest,
    verifySuccess
  } from '../../redux/actions/index';
import { myFirebase } from '../../Firebase/firebase';

export const loginUser = (email, password) => dispatch => {
    dispatch(requestLogin());
    myFirebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(receiveLogin(user));
      })
      .catch(error => {
        //Do something with the error if you want!
        dispatch(loginError(error.message));
      });
  };

export const logoutUser = (unsubscribeContact, unsubscribeEvent) => dispatch => {
    dispatch(requestLogout());
    unsubscribeContact()
    unsubscribeEvent()
    myFirebase
        .auth()
        .signOut()
        .then(() => {
        dispatch(receiveLogout());
        })
        .catch(error => {
        //Do something with the error if you want!
        dispatch(logoutError());
        });
    };

export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    myFirebase.auth().onAuthStateChanged(user => {
        if (user !== null) {
        dispatch(receiveLogin(user));
        }
        dispatch(verifySuccess());
    });
};