import {
  isLoading,
  fetchEventsSuccess,
  fetchEventsFailure,
  getUnsubscribeEvent,
  addEventRequest,
  editeEventRequest,
  deleteEventRequest
} from './index';

import { getBirthdayDate } from '../../Features/functions'

import Swal from 'sweetalert2';

import { eventCollection } from '../../Firebase/firebase';

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export function fetchEvents() {
  return dispatch => {
    dispatch(isLoading(true));
    let unsubscribe  = eventCollection.onSnapshot( snapshot => { 
          const events = [] 
          snapshot.forEach(doc => { 
              let event = doc.data()
              event.id = doc.id
              if (event.allDay && event.freq !== '') {
                events.push({
                  id: event.id,
                  title: event.title,
                  rrule: {
                    freq: event.freq,
                    dtstart: getBirthdayDate(event.start.toDate()).split('/').reverse().toString().replace(/,/g,"-")
                  },
                  allDay: true,
                  backgroundColor: event.hex,
                  borderColor: event.hex,
                  editable: true,
                  groupId : event.freq
                })
              } else {
                events.push({
                  id: event.id,
                  title: event.title,
                  start:event.start.toDate(),
                  end:event.end.toDate(),
                  backgroundColor: event.hex,
                  borderColor: event.hex,
                  editable: true,
                  allDay: event.allDay
                })
              }              
          })
          dispatch(fetchEventsSuccess(events))
          dispatch(isLoading(false))   
        }, 
        err => { 
          console.log(err)
          dispatch(fetchEventsFailure(err))
          dispatch(isLoading(false))
        } 
      )
      dispatch(getUnsubscribeEvent(unsubscribe))
  };
}

export function addEvent(event) {
  return dispatch => {
  eventCollection.add({
      title: event.title,
      allDay: event.allDay,
      start: event.start,
      end: event.end,
      hex: event.hex,
      freq: event.freq
    }).then(ref => {
      event.id = ref.id
      dispatch(addEventRequest(event))
      Toast.fire({
        icon: 'success',
        title: 'Evènement ' + event.title + ' Ajouté !'
      })
    })
    .catch(error => {
      console.error("Error updating document: ", error);
      Swal.fire('Oups !' , error, "error");
    })
  }
}

export function editEvent(event) {
  return dispatch => {
    eventCollection.doc(event.id).update({
      title: event.title,
      allDay: event.allDay,
      start: event.start,
      end: event.end,
      hex: event.hex,
      freq: event.freq
    })
    .then(() => {
      dispatch(editeEventRequest(event))      
      Toast.fire({
        icon: 'success',
        title: 'Evènement ' + event.title +' Modifié !'
      })
    })
    .catch(error => {
      console.error("Error updating document: ", error);
      Swal.fire('Oups !' , error, "error");
    })
  }
}

export function deleteEvent(id) {
  return dispatch => {
    eventCollection.doc(id).delete()
    .then(() => {
      dispatch(deleteEventRequest())
      Toast.fire({
        icon: 'success',
        title: 'Evènement Supprimé !'
      })
    })
    .catch(error => {
      console.error("Error removing document: ", error);
      Swal.fire('Oups !' , error, "error");
    })    
  }
}