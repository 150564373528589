import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";

import {
  addEvent,
  editEvent,
  deleteEvent
} from '../../redux/actions/event';

import {
  displayModalFormEvent,
} from '../../redux/actions/index';

import TextField from '@material-ui/core/TextField';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ReplyIcon from '@material-ui/icons/Reply';

import { CirclePicker  } from 'react-color';

import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 'auto',
      display: 'flex'      
    },
    button: {
      margin: theme.spacing(1),
    },
  },
}));

export default function ModalEventForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    setEvent(props.event)
  }, [props.event])
  
  const closeModal = () => {
    dispatch(displayModalFormEvent(false))
  };

  const handleChange = e => {
    setEvent({...event, [e.target.name]:e.target.value})
  }
  const handleChangeColor = (color,e) => {
    setEvent({...event, hex:color.hex})
  }

  const clickDeleteEvent = () => {
    
    Swal.fire({
      title: 'La suppression de l\'évènement sera définitive !',
      text: 'Etes-vous sûr de vouloir continuer ?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'non',
      confirmButtonText: 'oui',
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        dispatch(deleteEvent(event.id))
        closeModal()
      }
    })
  }

  const submit = e => {
    e.preventDefault();
    if (event.id) {
      dispatch(editEvent(event));
      closeModal()
    } else {
      dispatch(addEvent(event));
      closeModal()
    }
  }
  
  return (
    <div>
      <Dialog
          fullScreen={fullScreen}
          open={props.displayModal}
          onClose={closeModal}
          aria-labelledby="responsive-dialog-title"
          style={{zIndex:1000}}
        >
          <DialogTitle id="form-dialog-title">
          {event && event.id ?
           "Modifier l'évènement":
          "Nouvelle évènement"}
          </DialogTitle>
          <form onSubmit={submit}>
            <DialogContent>
              <div className={classes.form}>
                <div>
                <TextField
                  required
                  style={event && event.hex ?{backgroundColor: event.hex} : null}
                  InputProps={event && event.hex ?{
                    style: {color: "white" }}: null}
                  label="Titre"
                  name='title'
                  onChange={handleChange}
                  defaultValue={event && event.title ? event.title : null}
                />
                </div>
                {event && event.allDay ?
                <div>
                  <TextField
                  name='freq'
                  select
                  label="Répétition"
                  value={event && event.freq ? event.freq : ''}
                  onChange={handleChange}
                  helperText="Vous pouver choisir si l'évènement sera répéter"
                >
                  <MenuItem value={''}>Aucune</MenuItem>
                  <MenuItem value={'YEARLY'}>Tous les ans</MenuItem>
                  <MenuItem value={'MONTHLY'}>Tous les mois</MenuItem>
                  <MenuItem value={'WEEKLY'}>Toutes les semaines</MenuItem>
                  <MenuItem value={'DAILY'}>Tous les jours</MenuItem>
                </TextField></div> : null}
                <div style={{margin:'8px'}}>
                <CirclePicker 
                    color={event && event.hex ? event.hex : "#607d8b"} 
                    onChange={handleChangeColor}
                  />
                  <div style={{width:'100%', fontSize:'12px',marginTop:'10px', color:'rgba(0, 0, 0, 0.54)'}}>Couleur de l'évènement</div>
              </div>
              </div>          
          </DialogContent>        
          <DialogActions>          
            <Button 
              variant="contained"
              color="primary"
              size="small"
              type='submit'
              className={classes.button}
              startIcon={<SaveIcon />}
              autoFocus
            >
              Enregistrer
            </Button>
            {event && event.id ?
            <Button 
              variant="contained"
              color="secondary"
              size="small"
              onClick={clickDeleteEvent}
              className={classes.button}
              startIcon={<DeleteIcon />}
            >
              Supprimer
            </Button>
            : null}
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              startIcon={<ReplyIcon />}
              onClick={closeModal}
            >
              Retour
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}