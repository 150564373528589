import {
  isLoading,
  displayModalFormContact,
  searchContactsResult,  
  fetchBirthday,
  fetchContactsSuccess,
  fetchContactsFailure,
  getUnsubscribeContact,
  addContactRequest,
  editeContactRequest,
  deleteContactRequest
} from './index';

import {getAge, getBirthdayDate} from '../../Features/functions'

import Swal from 'sweetalert2';

import { contactCollection } from '../../Firebase/firebase';

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export function fetchContacts() {
    return dispatch => {
      dispatch(isLoading(true))
      let unsubscribe  = contactCollection.onSnapshot( snapshot => { 
            const contacts = []
            const birthdays = []
            snapshot.forEach(doc => { 
                let contact = doc.data()
                contact.id = doc.id
                contact.age = getAge(contact.birthday)
                contacts.push(contact)
                birthdays.push({
                  id: contact.id,
                  title: contact.lastName.charAt(0) + '. ' + contact.firstName,
                  rrule: {
                    freq: 'yearly',
                    dtstart: getBirthdayDate(contact.birthday.toDate()).split('/').reverse().toString().replace(/,/g,"-")
                  },
                  allDay: true,
                  textColor: 'white',
                  editable: false
                })
            })
            dispatch(fetchContactsSuccess(contacts))
            dispatch(fetchBirthday(birthdays))
            dispatch(isLoading(false))                         
            }, 
            err => { 
              console.log(err)
              dispatch(fetchContactsFailure(err))
              dispatch(isLoading(false)) 
            } 
        )
        dispatch(getUnsubscribeContact(unsubscribe))
    };
}


export function addContact(contact) {
    return dispatch => {
      contact.email = contact.email === undefined ? '' : contact.email
      contact.phone = parseInt(contact.phone)
      contactCollection.add({
          firstName: contact.firstName,
          lastName: contact.lastName,
          birthday: contact.birthday,
          phone: contact.phone,
          email: contact.email,
          address: contact.address,
          geopoint: contact.geopoint
        }).then(ref => {
          contact.id = ref.id
          dispatch(addContactRequest(contact))
          dispatch(displayModalFormContact(false))
          Toast.fire({
            icon: 'success',
            title: contact.firstName + ' ' + contact.lastName + ' Ajouté !'
          })
        })
        .catch(error => {
          console.error("Error updating document: ", error);
          Swal.fire('Oups !' , error, "error");
        })
      }
}

export function editContact(contact) {
  return dispatch => {
    contact.email = contact.email === undefined ? '' : contact.email
    contact.phone = parseInt(contact.phone)
    contactCollection.doc(contact.id).update({
        firstName: contact.firstName,
        lastName: contact.lastName,
        birthday: contact.birthday,
        phone: contact.phone,
        email: contact.email === undefined ? '' : contact.email,
        address: contact.address,
        geopoint: contact.geopoint
    })
    .then(() => {
      console.log('here')
      dispatch(editeContactRequest(contact))
      dispatch(displayModalFormContact(false))      
      Toast.fire({
        icon: 'success',
        title: contact.firstName + ' ' + contact.lastName + ' Modifié !'
      })
    })
    .catch(error => {
      console.error("Error updating document: ", error);
      Swal.fire('Oups !' , error, "error");
    })
  }
}

export function deleteContact(id) {
  return dispatch => {
    contactCollection.doc(id).delete()
    .then(() => {
      dispatch(deleteContactRequest())
      dispatch(displayModalFormContact(false))
      Toast.fire({
        icon: 'success',
        title: 'Contact Supprimé'
      })
    })
    .catch(error => {
      Swal.fire('Oups !' , error, "error");
    })    
  }
}

export function searchContacts(search, contacts) {
  const result = contacts.filter(contact => {
    const content = contact.firstName + ' ' + contact.lastName + ' ' + contact.address
    return content.toLowerCase().includes(search.toLowerCase())
  })
  return dispatch => {
    dispatch(searchContactsResult(result))
  }
}

export function sortContactsByFirstName(contacts) {
    contacts.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)

    return dispatch => {
        dispatch(searchContactsResult(contacts))
    }
}

export function sortContactsByLastName(contacts) {
    contacts.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)

    return dispatch => {
        dispatch(searchContactsResult(contacts))
    }
}

export function sortContactsByAge(contacts) {
    contacts.sort((a, b) => a.age - b.age);

    return dispatch => {
        dispatch(searchContactsResult(contacts))
    }
}

export function sortContactsByDate(contacts) {
    let today = new Date();
    contacts.sort((a, b) => {
        const dateA = a.birthday.toDate().setFullYear(today.getFullYear());
        const dateB = b.birthday.toDate().setFullYear(today.getFullYear());
        return dateA - dateB;
    });

    return dispatch => {
        dispatch(searchContactsResult(contacts))
    }
}