import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";

import {
  addContact,
  editContact,
  deleteContact
} from '../../redux/actions/contact';

import {
  displayModalFormContact,
} from '../../redux/actions/index';

import { getTimesTamp, getGeoPoint} from '../../Firebase/firebase'

import Localisation from '../Localisation';

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Swal from 'sweetalert2';

export default function ModalContactForm(props) {
  const dispatch = useDispatch();
  const openModal = props.displayModal;
  const [contact, setContact] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setContact(props.contact)
  }, [props.contact])


  const handleClickCloseModal = () => {
    dispatch(displayModalFormContact(false))
  };

  const handleChange = event => {
    setContact({...contact, [event.target.id]:event.target.value})
  }

  const handleDateChange = date => {
    if (date) {
      setContact({...contact, birthday: getTimesTamp(date.getTime())})
    }    
  };

  const handleLocalisationChange = localisation => {
    setContact({...contact, address: localisation.label, geopoint: getGeoPoint(localisation.geopoint[1], localisation.geopoint[0])})
  }

  const handleClickDeleteContact = () => {
    Swal.fire({
      title: 'La suppression du contact sera définitive !',
      text: 'Etes-vous sûr de vouloir continuer ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'non',
      confirmButtonText: 'oui',
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        dispatch(deleteContact(contact.id))
      }
    })
  }

  const submit = event => {
    event.preventDefault();
    if (contact.id) {
      dispatch(editContact(contact));      
    } else {
      dispatch(addContact(contact));
    }
  }
  
  return (
    <div>
      <Dialog
      fullWidth={true}
          fullScreen={fullScreen}
          open={openModal}
          onClose={handleClickCloseModal}
          aria-labelledby="responsive-dialog-title"
          style={{zIndex:1000}}
          maxWidth={'sm'}
        >
          <DialogTitle id="responsive-dialog-title">
            <AccountCircleIcon/>&nbsp;
          {contact ? "Editer contact": "Nouveau contact"}
          </DialogTitle>
          <form onSubmit={submit}>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    label="Nom"
                    id='lastName'
                    onChange={handleChange}
                    defaultValue={contact ? contact.lastName : null}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    variant="outlined"
                    label="prénom"
                    id='firstName'
                    onChange={handleChange}
                    defaultValue={contact ? contact.firstName : null}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                      required
                      placeholder='2000-01-17'
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="birthday"
                      label="Anniversaire"
                      value={contact && contact.birthday ? contact.birthday.toDate() : null}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                          'aria-label': 'change date',
                      }}
                      />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    label="Téléphone"
                    id='phone'
                    inputProps={{
                      maxLength: 10,
                      type : 'tel',
                      pattern: '[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}'
                    }}
                    onChange={handleChange}
                    defaultValue={contact && !isNaN(contact.phone) ? '0'+ parseInt(contact.phone) : null}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    label="m@ail"
                    id='email'
                    inputProps={{
                      type: 'email'
                    }}
                    onChange={handleChange}
                    defaultValue={contact ? contact.email : ''}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Localisation 
                    label='Adresse'
                    addValue={handleLocalisationChange}
                    defaultValue={contact ? contact.address : null}
                  />
                </Grid>
              </Grid>          
          </DialogContent>        
          <DialogActions className='text-center'>
          <Fab 
            size="small"
            color="primary" 
            aria-label="edit"
            type='submit'
          >
            {contact ? <EditIcon/> : <AddIcon/>}            
          </Fab>
            {contact && contact.id ?
            <Fab
              size="small"
              color="secondary"
              onClick={handleClickDeleteContact}
            >
              <DeleteIcon />
            </Fab>
            : null}
            <Fab
              size="small"
              onClick={handleClickCloseModal}
            >
              <ReplyIcon />
            </Fab>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}